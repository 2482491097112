<template>
  <router-link :to="{ name: 'AdminPrize' }" class="navbar-item">
    Atur Hadiah
  </router-link>

  <router-link :to="{ name: 'AdminVoucher' }" class="navbar-item">
    Atur Voucher
  </router-link>

  <router-link :to="{ name: 'AdminAdmin' }" class="navbar-item">
    Atur Admin
  </router-link>
</template>

<script>
export default {};
</script>

<style></style>
