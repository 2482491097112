import { createRouter, createWebHistory } from "vue-router";
import User_Service from "../services/user_services";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Admin/Admin_Login/admin_login_main.vue"),
    meta: {
      title: "Login",
    },
  },
  {
    path: "/config/admin-login",
    name: "AdminLogin",
    component: () => import("../views/Admin/Admin_Login/admin_login_main.vue"),
    meta: {
      title: "Login",
    },
  },
  {
    path: "/config/admin-voucher",
    name: "AdminVoucher",
    component: () =>
      import("../views/Admin/Admin_Voucher/admin_voucher_main.vue"),
    meta: {
      requiresAuth: true,
      title: "Atur Voucher",
    },
  },
  {
    path: "/config/admin-prize",
    name: "AdminPrize",
    component: () => import("../views/Admin/Admin_Prize/admin_prize_main.vue"),
    meta: {
      requiresAuth: true,
      title: "Atur Hadiah",
    },
  },
  {
    path: "/config/admin-admin",
    name: "AdminAdmin",
    component: () => import("../views/Admin/Admin_Admin/admin_admin_main.vue"),
    meta: {
      requiresAuth: true,
      title: "Atur Admin",
    },
  },
  {
    path: "/login/verify-otp",
    name: "VerifyOtp",
    component: () => import("../views/Admin/Verify_Otp/verify_otp_main.vue"),
    meta: {
      requiresAuth: true,
      title: "Verifikasi Otp",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.meta.requiresAuth &&
    !localStorage.token &&
    User_Service.verifyToken()
  ) {
    next("/");
  } else if (to.meta.requiresUnauth && !!localStorage.token) {
    next("/");
  } else {
    next();
  }
});

export default router;
