import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faGifts, faPlus } from "@fortawesome/free-solid-svg-icons";
library.add(faGifts, faPlus);

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import "@fortawesome/fontawesome-free/css/all.min.css"; //Font awesome dalam bentuk font
import "@/assets/main.scss";

import Axios from "axios";
// Axios.defaults.baseURL = "http://localhost:8080/api/v1";
// Axios.defaults.baseURL = "https://spinwin.master-jitu.space/api/v1";
Axios.defaults.baseURL = "https://admin.spin-gacor.pro/api/v1";
Axios.defaults.headers = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
  Accept: "application/json",
};

const app = createApp(App);
app.use(router);
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");
