<template>
  <nav
    id="main-top-navbar"
    class="navbar is-fixed-top is-black"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="siteMainNavbar"
        @click="toggleMenu"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div
      id="siteMainNavbar"
      class="navbar-menu is-hidden-mobile"
      :class="{ 'is-active': isMenuActive }"
    >
      <div class="navbar-start" id="siteMainNavbar-start">
        <template v-for="(menuItem, index) in menuItems" :key="index"
          ><router-link :to="{ name: menuItem.path }" class="navbar-item">
            {{ menuItem.title }}
          </router-link>
        </template>

        <AdminNavMenu v-if="loggedIn" />
      </div>

      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <template v-if="loggedIn"
              ><a class="button is-primary width-200" @click="handleLogout">
                <strong>Logout</strong>
              </a>
            </template>
          </div>
        </div>
      </div>
    </div>

    <DrawerMenu v-model:is-active="isMenuActive">
      <div class="drawer-items">
        <div class="navbar-item">== Menu ==</div>
      </div>
    </DrawerMenu>

    <div
      class="navbar-backdrop"
      :class="{ 'is-active': isMenuActive }"
      @click="isMenuActive = false"
    ></div>
  </nav>
  <div class="container is-fullheight" id="main-container">
    <router-view
      @handleLogin="handleLogin"
      @handleLogout="handleLogout"
      @handleVerifyOtp="handleVerifyOtp"
    />
  </div>
  <section id="pre-footer">
    <div v-html="preFooterContent"></div>
  </section>
  <footer id="footer is-black"></footer>
</template>

<script>
import router from "@/router";
import { onMounted, ref } from "vue";
import DrawerMenu from "@/components/DrawerMenu.vue";
import AdminNavMenu from "@/components/AdminNavMenu";

import UserService from "@/services/user_services";

export default {
  name: "App-Main",
  components: {
    DrawerMenu,
    AdminNavMenu,
  },
  setup() {
    const iconAssetUrl = ref("");
    const menuItems = ref([]);

    const rtpGameItems = ref([
      {
        id: 0,
        provider: "",
        icon: "",
      },
    ]);

    const selected = ref(0);
    const bottomNavItems = ref([
      {
        id: 0,
        icon: "",
        title: "",
        path: { name: "" },
        href: "",
      },
    ]);

    const isMenuActive = ref(false);

    const toggleMenu = () => {
      isMenuActive.value = !isMenuActive.value;
    };

    const loggedIn = ref(false);

    const tokenAvailable = () => {
      return localStorage.getItem("token");
    };

    const handleLogin = () => {
      loggedIn.value = true;

      router.push({ name: "AdminVoucher" });
    };

    const handleLogout = () => {
      localStorage.removeItem("token");
      localStorage.removeItem("id");
      UserService.clearDefaultHeader();
      router.push({ name: "Home" });
      loggedIn.value = false;
    };

    const handleVerifyOtp = () => {
      router.push({ name: "VerifyOtp" });
    };

    onMounted(() => {
      if (tokenAvailable()) {
        loggedIn.value = true;
      } else {
        loggedIn.value = false;
      }
    });
    return {
      iconAssetUrl,
      menuItems,
      rtpGameItems,
      isMenuActive,
      selected,
      bottomNavItems,
      loggedIn,

      toggleMenu,
      tokenAvailable,
      handleLogin,
      handleLogout,
      handleVerifyOtp,
    };
  },
  watch: {
    async $route(to) {
      document.title = to.meta.title || "RTP JITU";
      window.scrollTo(0, 0);
    },
    isMenuActive(value) {
      console.log(value);
    },
  },
};
</script>

<style>
.gradient-pulse {
  background: linear-gradient(to right, #fed804, #000000) !important;
  background-size: 200% 200% !important;
  animation: pulse 0.5s ease-in-out infinite !important;
  color: black;
}

.gradient-secondary {
  background-size: 200% 200% !important;
  animation: pulse 0.5s ease-in-out infinite !important;
  color: black !important;
}

.width-200 {
  width: 200px !important;
}

@keyframes pulse-gradient {
  0% {
    background-position: 0% 50% !important;
  }
  50% {
    background-position: 100% 50% !important;
  }
  100% {
    background-position: 0% 50% !important;
  }
}

.shining-border {
  border: 3px solid #d2a500 !important;
  box-shadow: 0px 0px 5px 0px #ffc803 !important;
  animation: shine 2s linear infinite !important;
}

@keyframes shine {
  0% {
    box-shadow: 0px 0px 5px 0px #ffc803 !important;
  }
  50% {
    box-shadow: 0px 0px 15px 5px #ffc803 !important;
  }
  100% {
    box-shadow: 0px 0px 5px 0px #ffc803 !important;
  }
}

.text-center {
  text-align: center;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* Navigation */

#nav .navbar-item,
#nav .navbar-link {
  /* color: #ffffff; */
}

#nav .navbar-item:hover,
#nav .navbar-link:hover {
  color: rgba(255, 167, 0, 1);
  background: #2c3e50;
  font-weight: bolder;
  font-style: italic;
}

#nav a.router-link-exact-active {
  color: rgba(255, 167, 0, 1);
  font-weight: bolder;
  font-style: italic;
}

.navbar-item-dropdown {
  color: black !important;
}

#main-top-navbar {
  z-index: 29;
}

#main-top-navbar img {
  max-height: 50px;
}

/* RUNNING TEXT */
#running-text {
  position: sticky;
  top: 55px;
  height: 2em;
  background-image: linear-gradient(to bottom right, #ffab00, #ffe000, #ffb100);
  z-index: 26;
  overflow: hidden;
}

#running-text #content {
  animation: marquee 25s linear infinite; /* specify the animation */
  white-space: nowrap;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-105%);
  }
}

/* Main Container background */
#main-container {
  background-size: cover;
  background-repeat: no-repeat;
  background: black;

  background-size: cover;
}

/* Body background */
body {
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.182),
      rgba(53, 53, 53, 0.553)
    ),
    url("") center;

  background-size: cover;
}

/* Backdrop untuk drawer */
.navbar-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0s linear 0.3s;
}

.navbar-backdrop.is-active {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out;
}

/* Padding karena ada navbar */
#main-container {
  padding: 50px 0px 50px;
}

@media (max-width: 768px) {
  #main-container {
    padding: 50px 0px 120px;
  }

  #running-text {
    position: sticky;
    top: 50px;
    height: 2em;
    background-image: linear-gradient(
      to bottom right,
      #ffab00,
      #ffe000,
      #ffb100
    );
    z-index: 26;
    overflow: hidden;
  }

  #running-text #content {
    animation: marquee 10s linear infinite; /* specify the animation */
    white-space: nowrap;
  }

  #main-top-navbar img {
    max-height: 4em;
  }
}

@media (max-width: 824px) {
  #running-text {
    position: sticky;
    top: 50px;
    height: 2em;
    background-image: linear-gradient(
      to bottom right,
      #ffab00,
      #ffe000,
      #ffb100
    );
    z-index: 26;
    overflow: hidden;
  }

  #running-text #content {
    animation: marquee 10s linear infinite; /* specify the animation */
    white-space: nowrap;
  }
}

#pre-footer {
  background-color: rgb(55, 55, 55);
  color: white;
  padding: 1em;
}

footer {
  background-color: black;
  color: white;
}

#copyright {
  padding: 1em;
}

#footer::before {
  display: block;
  height: 3px;
  background-color: #9a0f06;
  content: " ";
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  border-radius: 50%;
}

#content h1,
#content h2,
#content h3,
#content h4 {
  color: #ffab00;
}
</style>
